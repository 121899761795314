import { isAnyMtLanguages } from '../../../configs/profileConfig'
import { ProfileCategory_PEnum, ProfileStatus_PEnum, ProfileTier_DB_PEnum, StatusNotification_PEnum, TranslationFieldType_PEnum, VisabilityFilter_PEnum } from '../../../configs/pseudoEnums'
import { TranslatableText } from '../../../utility/TranslatableText'
import { isProfilePublished } from '../../../utility/Utils'

// ** Initial State
export const initialState = {
  isWetuUser: false,
  isUserAuthenticated: false,
  userEmails: {},   //Corresponds to API > DTO.Responses.UserEmailsDto: {string UserName, string UserEmail, string OperatorName, string OperatorEmail, List<string> IdentityEmails}
  adminUpdatedProfile: null,
  suggestions: { country: [], associations: [], directions: [], airports: [] },
  suggestedDestinationsLoading: false,
  suggestedDestinations: [],
  profilesInReview: [],
  profileSummaries: [],
  profilesInPending: [],
  features: {
    featuresData: {
      activities: [],
      property: [],
      rooms: [],
      services: []
    },
    languages: [],
    iBrochureLanguages: [],
    friendly: [],
    specialInterests: [],
    tourOperatorTourAgentGuidance: [],
    suitableFor: [],
    weatherRequirement: [],
    cuisine: [],
    ambiance: [],
    participation: [],
    skills: [],
    venueEquipment: []
  },
  profile: {
    id: '',
    accountIds: [],
    isDistributed: false,
    isEnabled: false,
    profileId: '',
    name: '',
    shortDescription: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.ShortDescription),
    richDescription: TranslatableText.getNew(false, '', TranslationFieldType_PEnum.RichDescription),
    travelInformation: {},        
    type: null,
    languageGuide:[{}],
    category: null,
    pin: { latitude: 0, longitude: 0, drivingLatitude: 0, drivingLongitude: 0 },
    streetAddress: '',
    postalCode: '',
    city: '',
    media: [],    //When we load a saved profile the media is passed as a child of profile, but we should never use profile.media in the UI.
    province: '',
    suburb: '',
    country: '',
    contacts: [],
    checkinTimeFrom: '',
    checkinTimeTo: '',
    checkoutTimeFrom: '',
    roomCount: 0,
    tentCount: 0,
    cabinCount: 0,
    seatCount: 0,
    maxGroupSize: 0,
    accommodationsCount: 0,
    restaurantsCount: 0,
    loungesCount: 0,
    terminalsCount: 0,
    shopsCount: 0,    
    accommodationsCount: 0,
    restaurantsCount: 0,
    loungesCount: 0,
    terminalsCount: 0,
    shopsCount: 0,
    destinationSize: '',
    population: '',
    destinationCurrency: '',
    starRating: 0,
    features: [], // On Wizard Publish will add English as a feature
    isChanged: false,
    isLoading: true,
    isCreated: false,
    isValidated: false,
    isReviewRequired: false,
    validationSucceeded: false,   //if isValidated==true && validationSucceeded==false then review is required.
    isPublished: false,
    isPublishing: false,
    isInReview: false,
    isPrivate: false,
    tier: ProfileTier_DB_PEnum.Core,
    iBrochureLanguages: ["English"],
    tags: [],
    associatedItems: [],
    aliases: [],
    resetObject: null,
    qualityAssuranceBody: '',
    alternativeRating: '',
    minimumChildAge: 0,
    availableAccommodationOnsite: 0,
    availableAccommodationOffsite: 0,
    redirectUrl: '',
    dateCreated: null,
    links: {},
    affiliate: null, //Setting the Default affiliate to null.
    affiliateCode: '',
    operatingHoursByDay: {
      isOpenPublicHolidays: false,
      isByAppointment: false
    },
    availableLanguages: [],
    officialLanguages: [],
    enabledDisabledProfile: {},
    upgradedDowngradedProfile: {},
    dmo: {
      activities: [],
      stays: [],
      destinations: [],
      polygon: {}
    },
    existsInCache: false,
    profileEditorErrors: {},
    showZoom: true,
    zoomTo: 0,
    minimumZoom: 0,
    maximumZoom: 0,
    //If they downgrade we cannot set isMachineTranslationsEnabled to false because if they click Upgrade again we need to re-show their MT langauges:
    isMtEnabled: (profile) => { return profile.isMachineTranslationsEnabled && profile.tier === ProfileTier_DB_PEnum.Professional && isAnyMtLanguages(profile.iBrochureLanguages) },
    isNightsbridgeData: false,
    showTierModal: false,
    hasErrorGettingNightsbridgeData: false,
    userName: '',    //Needed for ProfileUpdate changes, so passed back to API controllers that don't have accounts service.
    showProfileNameOnLanding: false
  },
  includeToolbarWithBreadcrumb: true,
  isAnyProProfiles: false,  //Used for Facebook button: it should only show if there is at least one Professional profile.
  profileGrid: {
    visibilityFilter: VisabilityFilter_PEnum.All,
    currentPage: 1,
    rowsPerPage: 50,
    searchText: '',
    sortColumn: {
      sortColumnName: undefined,
      sortDirection: 'desc'
    },
    totalPage: 1,
    tierFilter: '',
    typeFilter: '',
    statusFilter: '',
    recordCount: 0,
    noRecords: false,
    currentOperatorId: 0,  //Needed so we can create a profileAccounts array for a newly published profile, so we can insert it into the existing profileSummaries, to remove the flicker when the new data loads.
    currentUserName: '',   //Needed for profileAccounts array
    preventReload: false   //When we create a new profile we don't want the grid to refresh from the API.
  },
  profileEditor: {
    children: [],
    profileEditorImage: "",
    profileSliderImages: []
  },
  publishedProfile: {},
  deletedProfile: {},
  deletedProfileId: '',
  linkedScheduledDepartures: [],
  operators: [],
  statusNotification: StatusNotification_PEnum.None,
  isSavingButtonId: null,      //The ID of the SpinnerSaveButton that has been clicked. Set inside SpinnerSaveButton.
  isSaving: false,              //Set to true once in the axios function. Not set in SpinnerSaveButton because validation logic may prevent axios function running which means the spinner would not disappear if validation fails (and there could be multiple conditional statements before the axios function).
  hasUpgraded: false,
  requestedTier: undefined
}

// Update the operator account columns with new updated names/ids
const updateProfileOperators = (profileToUpdate) => {
  if (profileToUpdate.profileAccounts.length === 1) {
    profileToUpdate.accountName = profileToUpdate.profileAccounts[0].name ?? ''
  } else if (profileToUpdate.profileAccounts.length > 1) {
    profileToUpdate.accountName = `${profileToUpdate.profileAccounts.length} owners`
  } else {
    profileToUpdate.accountName = ''
  }

  if (profileToUpdate.profileAccounts.length >= 1) {
    profileToUpdate.accountIdAsString = profileToUpdate.profileAccounts.map(x => x.operatorId).join(', ')
  } else {
    profileToUpdate.accountIdAsString = ''
  }
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_PROFILE':
      return {
        ...state,
        profile: {
          ...state.profile,
          id: action.data.profile.id,
          profileId: action.data.profile.id,
          isCreated: action.data.profile.isCreated,
          isValidated: action.data.profile.isValidated,
          isPublished: action.data.profile.isPublished,
          isPrivate: action.data.profile.isPrivate,
          isChanged: false,
          tier: action.data.profile.tier,
          dateCreated: action.data.profile.dateCreated,
          shortDescription: action.data.profile.shortDescription, 
          richDescription: action.data.profile.richDescription,
          profileAccounts: action.data.profile.profileAccounts
        }
      }
    case 'SET_DELETED_PROFILE_ID':
      return { ...state, deletedProfileId: action.data }
      
    case 'INSERT_PROFILE_SUMMARY_AT_TOP': {
      if (state.profileSummaries && state.profileSummaries.length) {
        const profile = action.data
        const copyProfileSummaries = _.cloneDeep(state.profileSummaries)

        // if its null or undefined set to -1 (cant find)
        const profileIndex = copyProfileSummaries?.findIndex(x => x.id === profile.id) ?? -1

        if (profileIndex !== -1) {
          copyProfileSummaries.splice(profileIndex, 1)
        }
        copyProfileSummaries.unshift(profile)

        return {
          ...state, 
          profileSummaries: copyProfileSummaries,
          profileGrid: { ...state.profileGrid, preventReload: true }
        }
      }
      
      return { ...state }
    }
    case 'SET_PREVENT_GRID_RELOAD':
      return { ...state, profileGrid: { ...state.profileGrid, preventReload: action.data } }
    case 'SET_PROFILE_VISIBILITY':
      return { ...state, profileGrid: { ...state.profileGrid, visibilityFilter: action.data } }
    case 'SET_ROWS_PER_PAGE':
      return { ...state, profileGrid: { ...state.profileGrid, rowsPerPage: action.data } }
    case `SET_TIER_FILTER`:
      return { ...state, profileGrid: { ...state.profileGrid, tierFilter: action.data } }
    case `SET_STATUS_FILTER`:
      return { ...state, profileGrid: { ...state.profileGrid, statusFilter: action.data } }
    case `SET_TYPE_FILTER`:
      return { ...state, profileGrid: { ...state.profileGrid, typeFilter: action.data } }
    case `SET_SORT_COLUMN`:
      return { ...state, profileGrid: { ...state.profileGrid, sortColumn: { sortColumnName: action.data.sortColumnName, sortDirection: action.data.sortDirection } } }
    case `SET_CURRENT_PAGE`:
      return { ...state, profileGrid: { ...state.profileGrid, currentPage: action.data } }
    case `SET_IS_LOADING_PROFILES`:
      return { ...state, profileGrid: { ...state.profileGrid, isLoadingProfiles: action.data } }
    case `SET_CALL_SEQUENCE`:
      return { ...state, profileGrid: { ...state.profileGrid, callSequence: action.data } }
    case `SET_HAS_PROFILES`:
      return { ...state, hasProfiles: action.data }
    case `SET_HAS_PRO_PROFILES`:
      return { ...state, isAnyProProfiles: action.data }
    case 'SET_PROFILE_SUMMARIES':
      const latestSequence = state.profileGrid.callSequence
      const apiResponseSequence = action.data.sequence
      if (apiResponseSequence < latestSequence) {
        return { ...state, profileGrid: { ...state.profileGrid, isLoadingProfiles: false } }
      }
      const gridChanges = {
        totalPage: action.data.totalPage,
        recordCount: action.data.recordCount,
        currentOperatorId: action.data.operatorId,
        currentUserName: action.data.userName,
        isLoadingProfiles: false
      }
      const newProfileGrid = { ...state.profileGrid, ...gridChanges }
      return { ...state, profileGrid: newProfileGrid, profileSummaries: action.data.profileSummaries }
    case 'UPDATE_PROFILE_SUMMARY_CR':
      const copyExistingItems = _.cloneDeep(state.profileSummaries)
      const responseProfile = action.data
      const existing = copyExistingItems.find(x => x.id === responseProfile.id)

      if (existing) {
        existing.contentRating = responseProfile.contentRating
        existing.dateModified = responseProfile.dateModified
        existing.lastModifiedBy = responseProfile.lastModifiedBy
        existing.requestedTier = responseProfile.requestedTier
        existing.tier = responseProfile.tier
      }

      return { ...state, profileSummaries: copyExistingItems }
    case 'SET_SEARCH_TEXT':
      return { ...state, profileGrid: { ...state.profileGrid, searchText: action.data } }
    case 'SET_PROFILE':
      return { ...state, 
        profile: { 
          ...action.data, 
          dmo: action.data.category === ProfileCategory_PEnum.dmo ? state.profile.dmo : {
            activities: [],
            stays: [],
            destinations: []
          }
        } 
   }
    case 'SET_NAME':
      return { ...state, profile: { ...state.profile, name: action.data, isChanged: true, isValidated: false } }
    case 'SET_DESCRIPTION':
      return { ...state, profile: { ...state.profile, shortDescription: action.data, isChanged: true } }
    case 'SET_TYPE':
      return { ...state, profile: { ...state.profile, type: action.data, isValidated: false } }
    case 'SET_CATEGORY':
      return { ...state, profile: { ...state.profile, category: action.data } }
    case 'SET_NAME_ADDRESS_LOCATION':
      const data = action.data
      return {
        ...state,
          profile: { ...state.profile, 
          name: data.name, 
          pin: {
            latitude: data.pin.latitude || (state.profile.pin?.latitude ?? 0), 
            longitude: data.pin.longitude || (state.profile.pin?.longitude ?? 0),
            drivingLatitude: data.pin.drivingLatitude || (state.profile.pin?.drivingLatitude ?? 0), 
            drivingLongitude: data.pin.drivingLongitude || (state.profile.pin?.drivingLongitude ?? 0)
          }, 
          streetAddress: data.streetAddress || state.profile.streetAddress, 
          postalCode: data.postalCode || state.profile.postalCode,
          country: data.country || state.profile.country, 
          city: data.city || state.profile.city, 
          suburb: data.suburb || state.profile.suburb, 
          province: data.province ?? state.profile.province, 
          isChanged: true, 
          isValidated: false
        }
      }
    case 'SET_PIN':
      return { ...state, profile: { ...state.profile, pin: action.data, isChanged: true, isValidated: false } }
    case 'SET_STREET_ADDRESS':
      return { ...state, profile: { ...state.profile, streetAddress: action.data, isChanged: true } }
    case 'SET_POSTAL_CODE':
      return { ...state, profile: { ...state.profile, postalCode: action.data, isChanged: true } }
    case 'SET_CITY':
      return { ...state, profile: { ...state.profile, city: action.data, isChanged: true } }
    case 'SET_PROVINCE':
        return { ...state, profile: { ...state.profile, province: action.data, isChanged: true } }
    case 'SET_SUBURB':
        return { ...state, profile: { ...state.profile, suburb: action.data, isChanged: true } }
    case 'SET_COUNTRY':
      return { ...state, profile: { ...state.profile, country: action.data, isChanged: true } }
    case 'SET_PROFILE_CONTACT':
      let found = false
      const existingItems = (!state.profile.contacts 
      || state.profile.contacts.length === 0) ? [] : state.profile.contacts.map((existingItem) => {
        if (existingItem.type === action.data.type && existingItem.fieldType === action.data.fieldType) {
          found = true
          return action.data
        }
        return existingItem
      })
      
      if (!found) {
        existingItems.push(action.data)
      }
      
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: existingItems,
          isChanged: true
        }
      }
    case 'DELETE_PROFILE_CONTACT':
      const contactsCopy = Object.assign([], state.profile.contacts)
      if (action.data.type && action.data.fieldType) {
        const findIndex = contactsCopy?.findIndex(x => x.type === action.data.type && x.fieldType === action.data.fieldType)
        if (findIndex >= 0) {
          contactsCopy.splice(findIndex, 1)
        }
      } else if (!action.data.fieldType && action.data.type) {
        const fields = contactsCopy.filter(y =>  y.type === action.data.type)
        fields.forEach(field => {
          const findIndex = contactsCopy?.findIndex(z => z.type === field.type && z.fieldType === field.fieldType)
          if (findIndex >= 0) {
            contactsCopy.splice(findIndex, 1)
          }
        })
      }
      return { ...state, profile: { ...state.profile, contacts: contactsCopy, isChanged: true } }
    case 'SET_PROFILE_CHECK_IN_FROM':
      return { ...state, profile: { ...state.profile, checkinTimeFrom: action.data, isChanged: true } }
    case 'SET_PROFILE_CHECK_IN_TO':
      return { ...state, profile: { ...state.profile, checkinTimeTo: action.data, isChanged: true } }
    case 'SET_PROFILE_CHECK_OUT_FROM':
      return { ...state, profile: { ...state.profile, checkoutTimeFrom: action.data, isChanged: true } }
      
    case 'SET_ACCOMMODATIONS_COUNT':
      return { ...state, profile: { ...state.profile, accommodationsCount: action.data, isChanged: true } }
    case 'SET_RESTAURANTS_COUNT':
      return { ...state, profile: { ...state.profile, restaurantsCount: action.data, isChanged: true } }
    case 'SET_LOUNGES_COUNT':
      return { ...state, profile: { ...state.profile, loungesCount: action.data, isChanged: true } }
    case 'SET_TERMINALS_COUNT':
      return { ...state, profile: { ...state.profile, terminalsCount: action.data, isChanged: true } }
    case 'SET_SHOPS_COUNT':
      return { ...state, profile: { ...state.profile, shopsCount: action.data, isChanged: true } }

    case 'SET_PROFILE_ROOM_COUNT':
      return { ...state, profile: { ...state.profile, roomCount: action.data, isChanged: true } }
    case 'SET_PROFILE_CABIN_COUNT':
      return { ...state, profile: { ...state.profile, cabinCount: action.data, isChanged: true } }
    case 'SET_PROFILE_TENT_COUNT':
      return { ...state, profile: { ...state.profile, tentCount: action.data, isChanged: true } }
    case 'SET_PROFILE_SEAT_COUNT':
      return { ...state, profile: { ...state.profile, seatCount: action.data, isChanged: true } }
    case 'SET_PROFILE_MAX_GROUP_SIZE':
      return { ...state, profile: { ...state.profile, maxGroupSize: action.data, isChanged: true } }
    case 'SET_PROFILE_MINIMUM_CHILD_AGE':
      return { ...state, profile: { ...state.profile, minimumChildAge: action.data, isChanged: true } }
    case 'SET_PROFILE_ACCOMMODATION_ON_SITE':
      return { ...state, profile: { ...state.profile, availableAccommodationOnsite: action.data, isChanged: true } }
    case 'SET_PROFILE_ACCOMMODATION_OFF_SITE':
      return { ...state, profile: { ...state.profile, availableAccommodationOffsite: action.data, isChanged: true } }
    case 'SET_PROFILE_STAR_RATING':
      return { ...state, profile: { ...state.profile, starRating: action.data, isChanged: true } }
    case 'SET_PROFILE_QUALITY_ASSURANCE_BODY':
      return { ...state, profile: { ...state.profile, qualityAssuranceBody: action.data, isChanged: true } }
    case 'SET_PROFILE_ALTERNATIVE_RATING':
      return { ...state, profile: { ...state.profile, alternativeRating: action.data, isChanged: true } }
    case 'SET_PROFILE_CAPACITY':
      return { ...state, profile: { ...state.profile, capacity: action.data, isChanged: true } }
    case 'SET_PROFILE_DIMENSIONS':
      return { ...state, profile: { ...state.profile, dimensions: action.data, isChanged: true } }
    case 'SET_PROFILE_IATA_CODE':
      return { ...state, profile: { ...state.profile, iataCode: action.data, isChanged: true } }
    case 'SET_PROFILE_ICAO_CODE':
      return { ...state, profile: { ...state.profile, icaoCode: action.data, isChanged: true } }
    case 'SET_PROFILE_INFRASTRUCTURE_SIZE':
      return { ...state, profile: { ...state.profile, infrastructureSize: action.data, isChanged: true } }
    case 'SET_FEATURES':
      return { ...state, profile: { ...state.profile, features: action.data, isChanged: true } }
    case 'RESET_PROFILE':
      return { ...state, profile: { ...initialState.profile, resetObject: new Date() } }
    case 'SET_IS_PUBLISHING':
      return { ...state, profile: { ...state.profile, isPublishing: action.data } }
    case 'SET_TIER':
      return { ...state, profile: { ...state.profile, tier: action.data } }
    case 'SET_VISIBILITY':
        return { ...state, profile: { ...state.profile, isPrivate: action.data } }
    case 'SET_WIZARD_SAVE_PAGE_NUMBER':
      return { ...state, profile: { ...state.profile, wizardSavePageNum: action.data, saveTrigger: new Date() } }
    case 'SET_LOAD_TRIGGER':
      return { ...state, profile: { ...state.profile, wizardSavePageNum: action.data, loadTrigger: new Date() } }
    case 'SET_IS_LOADING':
      return { ...state, profile: { ...state.profile, isLoading: action.data } }
    case 'SET_IS_CHANGED':
      return { ...state, profile: { ...state.profile, isChanged: action.data } }
    case 'SET_REDIRECT_URL':
      return { ...state, profile: { ...state.profile, redirectUrl: action.data } }
    case 'SET_ACTIVE_PROFILE_EDITOR_CHILDREN':
      return { ...state, profileEditor: { ...state.profileEditor, children: action.data } }
    case 'SET_PROFILE_EDITOR_IMAGE':
      return { ...state, profileEditor: { ...state.profileEditor, profileEditorImage: action.data } }
    case 'SET_PUBLISHED_PROFILE':
      return { ...state, publishedProfile: action.data }    
    case 'SET_PROFILE_OFFICIAL_LANGUAGES':
      return { ...state, profile: { ...state.profile, officialLanguages: action.data, isChanged: true } }
    case 'SET_PROFILE_POPULATION':
      return { ...state, profile: { ...state.profile, population: action.data, isChanged: true } }
    case 'SET_PROFILE_DESTINATION_SIZE':
      return { ...state, profile: { ...state.profile, destinationSize: action.data, isChanged: true } }
    case 'SET_PROFILE_DESTINATION_CURRENCY':
      return { ...state, profile: { ...state.profile, destinationCurrency: action.data, isChanged: true } }
    case 'SET_PROFILE_LANGUAGE_GUIDE':
      return { ...state, profile: { ...state.profile, languageGuide: action.data, isChanged: true } }    
    case 'SET_IBROCHURE_LANGUAGES':
      return { ...state, profile: { ...state.profile, iBrochureLanguages: action.data } }  //We don't set isChanged here because this happens on load. 
    case 'SET_PROFILE_TAGS':
      return { ...state, profile: { ...state.profile, tags: action.data, isChanged: true } }
    case 'SET_PROFILE_SEARCH_ALIASES':
      return { ...state, profile: { ...state.profile, aliases: action.data, isChanged: true } }
    case 'SET_PROFILE_TRANSLATABLE_TEXT_SHORT_DESCRIPTION':
      return { ...state, profile: { ...state.profile, shortDescription: action.data, isChanged: true } }
    case 'SET_PROFILE_TRANSLATABLE_TEXT_RICH_DESCRIPTION':
      return { ...state, profile: { ...state.profile, richDescription: action.data } }
    case 'SET_PROFILE_LINKS':
      return { ...state, profile: { ...state.profile, links: action.data, isChanged: true } }
    case 'SET_ASSOCIATED_ITEMS':
      return { ...state, profile: { ...state.profile, associatedItems: action.data, isChanged: true } }
    case 'SET_ASSOCIATED_ITEMS_UNCHANGED':
       return { ...state, profile: { ...state.profile, associatedItems: action.data, isChanged: false } }
    case 'SET_PROFILE_AFFILIATE':
      return { ...state, profile: { ...state.profile, affiliate: action.data, isChanged: true } }
    case 'SET_PROFILE_SLIDER_IMAGES':
      return { ...state, profileEditor: { ...state.profileEditor, profileSliderImages: action.data } }
    case 'SET_OPERATING_HOURS_BY_DAY':
      return { ...state, profile: { ...state.profile, operatingHoursByDay: action.data } }
    case 'GET_ALL_FEATURES':
      return { ...state, features: action.data }
    case 'SET_PROFILE_FEATURE_LANGUAGES':
      return { ...state, profile: { ...state.profile, availableLanguages: action.data } }
    case `FILTER_BY_COUNTRIES`:
      return { ...state, suggestions: {...state.suggestions, [`country`]: action.data.suggestions }}
    case `FILTER_BY_SEARCH`:
      return { ...state, suggestions: {...state.suggestions, [action.data.suggestionsFor]: action.data.suggestions }}
    case 'CLEAR_PROFILE_AUTOCOMPLETE': 
      return { ...state, suggestions: {...state.suggestions, [action.data.suggestionsToClear]: [] }}
    case 'GET_PROFILES_IN_REVIEW':
      return { ...state,  profilesInReview: action.data }
    case 'SET_PROFILES_IN_PENDING':
        return { ...state,  profilesInPending: action.data }
    case 'ENABLE_DISABLE_PROFILE': {
      const profileSummaryItemsCopy = _.cloneDeep(state.profileSummaries)
      const profileSummaryToUpdate = profileSummaryItemsCopy.find(p => p.id === action.data.id)
      
      if (profileSummaryToUpdate) {
        profileSummaryToUpdate.status = action.data.status
        profileSummaryToUpdate.isPublished = isProfilePublished(action.data.status)
        profileSummaryToUpdate.isEnabled = action.data.isEnabled
      }
      
      return { ...state, profileSummaries: profileSummaryItemsCopy, enabledDisabledProfile: action.data}
    }
    case 'CLEAR_ENABLE_DISABLE_PROFILE':
      return { ...state,  enabledDisabledProfile: {}}
    case 'SET_ITEMS_FOR_DMO_PROFILE':
      const { type, list } = action.data
      return { ...state,  profile: { ...state.profile, dmo: {...state.profile.dmo, [`${type}`]: list} }}
    case 'UPGRADE_DOWNGRADE_PROFILE':
      return { ...state,  upgradedDowngradedProfile: action.data}
    case 'CLEAR_UPGRADE_DOWNGRADE_PROFILE':
      return { ...state,  upgradedDowngradedProfile: {}}
    case 'UPDATE_PROFILE_TIER':
      const copySummaries = _.cloneDeep(state.profileSummaries)
      const profSummary = copySummaries.find(p => p.id === action.data.profileId)
      if (profSummary) {
        profSummary.tier = action.data.tier
      }
      return {
        ...state, 
        profileSummaries: copySummaries,
        profileGrid: { ...state.profileGrid }
      }
    case 'SET_DELETED_PROFILE':
      return { ...state, deletedProfile: action.data }
    case 'REMOVE_PROFILE':
      const profileSummaries = _.cloneDeep(state.profileSummaries)
      const summary = profileSummaries.find(p => p.id === action.data)
      if (profileSummaries.indexOf(summary) > -1) {
        profileSummaries.splice(profileSummaries.indexOf(summary), 1)
      }
      return { ...state, profileSummaries: profileSummaries }
    case 'SET_IS_MACHINE_TRANSLATIONS_ENABLED':
      return { ...state, profile: { ...state.profile, isMachineTranslationsEnabled: action.data, isChanged: true } }
    case 'SET_EXISTS_IN_CACHE':
        return { ...state, profile: { ...state.profile, existsInCache: action.data } }
    case 'SET_OPERATORS':
      return { ...state, operators: action.data }
    case 'SET_PROFILE_OPERATORS':
      return { ...state, profile: { ...state.profile, operators: action.data }}
    case 'SET_SUGGESTED_DESTINATIONS':
      return {...state, suggestedDestinations: action.data, suggestedDestinationsLoading: false }
    case 'SET_SUGGESTED_DESTINATIONS_LOADING':
      return {...state, suggestedDestinations: action.data.loading ? [] : state.suggestedDestinations, suggestedDestinationsLoading: action.data.loading }
    case 'SET_SELECTED_DESTINATION':
      return {...state, profile: {...state.profile, associatedItems: action.data }}
    case 'SET_PREFERRED_DESTINATION': {
      const profileAssociatedClone = _.cloneDeep(state.profile.associatedItems)
      
      if (action.data.isCampSite) {
        const mobSafAssoc = profileAssociatedClone.find(associatedItem => associatedItem.associatedProfileId === action.data.profileId)
        mobSafAssoc.destinations.forEach(element => {
          element.isPreferred = false
          if (element.id === action.data.destinationId) {
            element.isPreferred = true
          }          
        })
      } else {
        profileAssociatedClone.forEach(element => {
          element.isPreferred = false
          if (element.id === action.data.destinationId) {
            element.isPreferred = true
          }
        })
      }

      return {...state, profile: {...state.profile, associatedItems: profileAssociatedClone }}
    }
    case 'SET_PROFILE_STATUS':
      return { ...state, profile: { ...state.profile, status: action.data, isPublished: isProfilePublished(action.data), isChanged: true } }
    case 'UPDATE_PROFILE_STATUS_IN_GRID': {
      const profileList = Object.assign([], state.profileSummaries)
      const matchingProfile = profileList.find(p => p.id === action.data.id)
      if (profileList.indexOf(matchingProfile) > -1) {
        matchingProfile.status = action.data.status
      }
      return {...state, profileSummaries: profileList}
    }
    case 'UPDATE_PROFILE_SUMMARY_IN_GRID': {
      const profileList = _.cloneDeep(state.profileSummaries)
      const matchingProfile = profileList.find(p => p.id === action.data.id)
      if (profileList.indexOf(matchingProfile) > -1) {
        const { name, type, category, country, province, city, suburb, status, dateModified, lastModifiedBy} = action.data
        matchingProfile.name = name
        matchingProfile.type = type
        matchingProfile.category = category
        matchingProfile.country = country
        matchingProfile.province = province
        matchingProfile.city = city
        matchingProfile.suburb = suburb
        matchingProfile.status = status
        matchingProfile.dateModified = dateModified
        matchingProfile.lastModifiedBy = lastModifiedBy
      }
      return {...state, profileSummaries: profileList}
    }
    case 'SET_STATUS_NOTIFICATION':
      return { ...state, profile: { ...state.profile, statusNotification: action.data} }
    case 'UPDATE_PROFILE_SUMMARY' :
      const { statusNotification, dateModified, lastModifiedBy, dateCreated, createdBy, profileId  } = action.data
      const profileSummariesCopy = _.cloneDeep(state.profileSummaries)
      const foundProfileSummary = profileSummariesCopy.find(p => p.id === profileId)
      if (foundProfileSummary) {
        if (statusNotification) {
          foundProfileSummary.statusNotification = statusNotification
        }

        if (dateCreated) {
          foundProfileSummary.dateCreated = dateCreated
        }

        if (createdBy) {
          foundProfileSummary.createdBy = createdBy
        }

        foundProfileSummary.dateModified = dateModified
        foundProfileSummary.lastModifiedBy = lastModifiedBy
      }
      return {...state, profileSummaries: profileSummariesCopy}
    case 'SET_IS_PUBLISHED':
      return { ...state, profile: { ...state.profile, isPublished: action.data } }
    case 'PROFILE_FIELD_VALIDATION_CLEAR': {
      return { ...state,  profile: { ...state.profile, profileEditorErrors: {}}}
    }
    case 'PROFILE_FIELD_VALIDATION_FAILURE':
      const errorsCopy = _.cloneDeep(state.profile.profileEditorErrors)
      if (action.data.error) {
        errorsCopy[action.data.field] = action.data.error
      } else {
        if (errorsCopy.hasOwnProperty(action.data.field)) {
          delete errorsCopy[action.data.field]
        }
      }
      return { ...state,  profile: { ...state.profile, profileEditorErrors: errorsCopy}}
    case 'SET_MAXIMUM_ZOOM':
      return { ...state,  profile: { ...state.profile, maximumZoom: action.data}}
    case 'SET_MINIMUM_ZOOM':
      return { ...state,  profile: { ...state.profile, minimumZoom: action.data}}
    case 'SET_ZOOM_TO':
      return { ...state,  profile: { ...state.profile, zoomTo: action.data}}
    case 'SET_SHOW_ZOOM':
      return { ...state,  profile: { ...state.profile, showZoom: action.data}}
    case 'SET_IS_SAVING_BUTTON_ID':
      return { ...state, isSavingButtonId: action.data }
    case 'SET_IS_SAVING':
        return { ...state, isSaving: action.data }
    case 'CLEAR_IS_SAVING':
      return { ...state, isSavingButtonId: null, isSaving: false }  //Resets both spinner button properties for convenience.
    case 'SET_ADMIN_UPDATED_PROFILE':
      return { ...state, adminUpdatedProfile: action.data }
    case 'SET_AFFILIATE':
        return { ...state,  profile: { ...state.profile, affiliate: action.data}}
    case 'SET_AFFILIATE_CODE':
      return { ...state,  profile: { ...state.profile, affiliateCode: action.data}}
    case 'SET_PRINCIPAL_ID':
      return { ...state,  profile: { ...state.profile, principalId: action.data}}
    case 'UPDATE_PROFILE_OPERATOR_IDS':
      const summariesCopy = _.cloneDeep(state.profileSummaries)      
      const profileToUpdate = summariesCopy.find(p => p.id === action.data.profileId)

      profileToUpdate.profileAccounts = [...action.data.profileOperators]

      updateProfileOperators(profileToUpdate)

      return {...state, profileSummaries: summariesCopy}
    case 'SET_USERDATA':
      return { ...state,  isWetuUser: action.data.IsWetu, isUserAuthenticated: true }
    case 'SET_USER_EMAILS':
      return { ...state,  userEmails: action.data }
    case 'SET_IS_WETU_USER':
      return { ...state,  isWetuUser: action.data }
    case 'INCLUDE_TOOLBAR_WITH_BREADCRUMB':
      return { ...state,  includeToolbarWithBreadcrumb: action.data}
    case 'SHOW_TIER_MODAL':
      return { ...state,  profile: { ...state.profile, showTierModal: action.data}}
    case 'SET_PROFILE_EDITOR_URL':
      return { ...state,  profileEditorUrl: action.data}
    case 'SET_IS_NIGHTSBRIDGE_DATA':
      return { ...state,  profile: { ...state.profile, isNightsbridgeData: action.data, hasErrorGettingNightsbridgeData: false, showTierModal: action.data}, isSaving: false}
    case 'ERROR_GETTING_NIGHTSBRIDGE_DATA':
      return { ...state,  profile: { ...state.profile, hasErrorGettingNightsbridgeData: true, showTierModal: false}, isSaving: false}
    case 'CLEAR_GET_NIGHTSBRIDGE_DATA_ERROR':
      return { ...state,  profile: { ...state.profile, hasErrorGettingNightsbridgeData: false}, isSaving: false}
    case 'ADD_REMOVE_PROFILE_OPERATOR_IDS': {
      const profileSummaryCopy = _.cloneDeep(state.profileSummaries)
      
      const profileToUpdate = profileSummaryCopy.find(p => p.id === action.data.profileId)

      if (profileToUpdate) {
        if (action.data.profileOperators.length > 0) {
          profileToUpdate.profileAccounts = [...action.data.profileOperators]
        }
      }

      updateProfileOperators(profileToUpdate)

      return {...state, profileSummaries: profileSummaryCopy}
    }
    
    case 'SSE_ENABLE_DISABLE_PROFILE': {
      const profileSummaryItemsCopy = _.cloneDeep(state.profileSummaries)
      const profileItemToUpdate = profileSummaryItemsCopy.find(p => p.id === action.data.profileId)
      if (profileItemToUpdate) {
        profileItemToUpdate.status = action.data.isEnabled 
          ? ProfileStatus_PEnum.Active 
          : ProfileStatus_PEnum.Disabled
        profileItemToUpdate.isEnabled = action.data.isEnabled
      }
      return { ...state,  profileSummaries: profileSummaryItemsCopy}
    }

    case 'HAS_TIER_UPGRADED': {
      return {...state, hasUpgraded: action.data}
    }

    case 'ADD_ACCOUNT_TO_PROFILE':
      const profileSummariesStateCopy = _.cloneDeep(state.profileSummaries)
      const profileSummary = profileSummariesStateCopy.find(p => p.id === action.data.profileId)
      if (profileSummary) {
        profileSummary.profileAccounts.push(action.data)
      }
      return { ...state, profileSummaries: profileSummariesStateCopy }
    case 'SET_PROFILE_NAME_ON_LANDING':
      return { ...state, profile: { ...state.profile, showProfileNameOnLanding: action.data}}
    default:
      return state
  }
}

export default profileReducer